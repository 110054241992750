<template>
    <div>
        <div class="modal_header">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple">
                        <div class="title_h">
                           {{ $t('message.contract_payment') }}
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple-light">
                        <el-row class="button_r">
                           <!-- <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit">{{$t('message.save_and_close')}}</el-button> -->
                            <el-button @click="resetForm('form')">{{ $t('message.close') }}</el-button>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="row">
            <div class="col-12 mt-2">
                <div class="modal-body">
                    <el-row :gutter="24">
                        <el-col :span="24" class="mb-1">
                            <el-card shadow="always">
                                <table class="table table-striped table-bordered mb-0" v-loading="loadingData">
                                    <tbody>
                                        <tr>
                                            <td>{{ $t('message.organization') }}:</td>
                                            <td>{{ $t('message.contracts_n') }}:</td>
                                            <td>{{ $t('message.deal_amount') }}:</td>
                                            <td>{{ $t('message.remaind') }}:</td>
                                        </tr>
                                        <tr>
                                            <td>{{ model.organization ?  model.organization.company_name : '-' }}</td>
                                            <td># {{ model.number }}</td>
                                            <td>{{ model.sum | formatMoney}}</td>
                                            <td>{{ model.remainder | formatMoney }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </el-card>
                        </el-col>
                        <el-col :span="24" class="mb-1">
                            <el-card shadow="always">
                                <contractTransactions />
                            </el-card>
                        </el-col>
                        <el-col :span="24" class="mb-3">
                            <!--  -->
                            <order-table></order-table>
                            <!--  -->
                        </el-col>
                    </el-row>
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapGetters,mapActions} from 'vuex';
    import orderTable from "./include/crm-orders";
    import contractTransactions from "./include/contract-transactions";
    import form from '@/utils/mixins/form';
    export default {
        name:'crm-transaction',
        mixins: [form],
        props: {
            reloadModel: {type: Boolean, default: true},
        },
        components:{
            orderTable,contractTransactions
        },
        data(){
            return {
                loadingData: false,
                
            }
        },
        watch: {
            reloadModel: {
                handler: function () {
                    if (this.reloadModel){
                        this.loadingData = true;
                    }
                },
                deep: true
            },
            model: {
                handler: function () {
                    this.loadingData = false;
                },
                deep: true
            }
        },
        created(){
            this.loadingData = true;
        },
        mounted(){},
        computed: {
            ...mapGetters({
                model: 'contracts/model',
            })
        },
        methods:{
            ...mapActions({
                save: 'contracts/update',
                
            }),
            loadData(){
                this.loadingData = true;
            },
            
            resetForm(formName) {
                // this.$refs[formName].resetFields();  
                this.$emit('c-close',{drawer: 'drawerTransaction', emptyModel: 'emptyModel'});
            },
        }
    }

</script>
